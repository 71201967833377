@import '../../variables.scss';

.client-details {
	flex: 1;
	height: 1px;

	.page-section:first-child {
		margin-right: 25px;

		div {
			width: 100%;
		}

		.inputs {
			margin-bottom: 40px;
		}

		textarea {
			margin-bottom: 40px;
		}

		td {
			font-size: 18px;
			display: flex;
			justify-content: space-between;

			.user-data {
				font-weight: 500;
				color: $blue-9;
				font-size: 18px;
				text-align: end;
				margin-left: 10px;
			}
		}

		.balance {
			margin-top: 40px;

			label {
				margin-bottom: 10px;
				color: $gray-9;
				font-size: 18px;
			}

			span {
				color: $blue-9;
				font-size: 22px;
				font-weight: 500;

				&.red {
					color: $red-6;
					font-size: inherit;
					font-weight: inherit;
				}
			}
		}
	}

	@media (max-width: 1400px) {
		overflow-y: auto;

		> div {
			flex-direction: column;

			.page-section {
				height: unset;
				margin-bottom: 40px;

				&:first-child {
					margin-right: unset;
				}

				&:last-child {
					margin-bottom: 10px;
				}
			}
		}
	}
}
