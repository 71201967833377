.ant-table-wrapper {
	flex: 1;
	height: 1px;

	.ant-spin-nested-loading {
		height: 100%;

		.ant-spin-container {
			height: 100%;

			.ant-table {
				height: 100%;

				.ant-table-container {
					height: 100%;

					.ant-table-content {
						height: 100%;
						max-height: fit-content;
						overflow-y: auto !important;

						> table {
							position: relative;

							tr {
								&:nth-child(2n + 1) {
									background-color: #fafafa;
								}
								> th {
									position: sticky;
									top: 0;
									background: #fafafa;
									z-index: 1;
									font-size: 18px;
									font-weight: 600;
								}

								> td {
									font-size: 18px;
									font-weight: 400;
									color: $gray-8;
								}
							}
						}
					}
				}
			}
		}
	}
}
