@import '../../variables.scss';

.driver-details {
	flex: 1;
	height: 1px;

	.page-section {
		div,
		table {
			width: 100%;
		}

		td {
			width: 50%;
			span {
				margin-left: 10px;
			}
		}

		.ant-space {
			margin-bottom: 40px;
		}

		> .ant-space:nth-child(3) {
			align-items: flex-end;
		}

		.user-data {
			font-weight: 500;
			color: $blue-9;
			font-size: 18px;
		}
	}
}
