@import '../../variables.scss';

.taskDetails {
	height: 1px;
	flex: 1;

	> div {
		overflow-y: auto;
	}

	.page-section {
		overflow: unset;

		.drivers {
			.ant-space {
				flex: 1;
				> .ant-space-item:first-child {
					max-width: 300px;
					flex: 1;
					.ant-select {
						width: 100%;
					}
				}
			}
			margin-bottom: 50px;
		}
		table,
		tbody {
			width: 100%;
		}

		tbody {
			tr {
				display: flex;
				width: 100%;

				td {
					padding-bottom: 35px;
					flex: 1;

					.price-type {
						color: $blue-7;
					}

					p {
						.user-data {
							font-size: 24px;
							font-weight: 400;
							color: $blue-7;
						}
					}

					p:first-child {
						padding-bottom: 10px;
					}

					button {
						align-self: center;
					}

					> .date {
						display: flex;
						align-items: end;

						p {
							padding-bottom: 0;
						}

						button {
							margin-left: 10px;
							align-self: flex-end;
						}
					}
				}
			}

			tr:last-child td:last-child {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				&.button {
					button {
						align-self: start;
					}
				}
			}
		}
	}

	> div:first-child {
		.page-section:first-child {
			// margin-right: 50px;

			> table {
				tr:first-child {
					td:first-child {
						flex: 7;
					}

					td:last-child {
						flex: 3;
					}
				}
			}

			.table {
				table {
					width: 50%;

					td {
						padding-bottom: unset;
					}

					td:last-child {
						text-align: end;

						a,
						span {
							font-size: 18px;
							font-weight: 500;
						}

						.creation-data {
							font-weight: 500;
							color: $blue-9;
							font-size: 18px;
							padding-left: 10px;
						}
					}
				}

				margin-bottom: 75px;
			}
		}
	}
}
