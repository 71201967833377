@import '../variables.scss';

.not-found {
	margin-bottom: 213px;

	h1,
	p {
		color: $gray-8;
		text-align: center;
	}

	h1 {
		font-size: 72px;
		font-weight: 600;
		margin-bottom: 100px;
	}

	p {
		font-size: 28px;
		font-weight: 500;
		margin-bottom: 17px;
	}

	.lost {
		font-size: 48px;
		font-weight: 600;
	}

	a {
		font-size: 24px;
		font-weight: 500;
		text-decoration: underline;
	}
}
