.expenses {
	flex: 1;
	height: 1px;

	.inputs {
		flex-wrap: wrap;
		// gap: 20px;
		margin: -20px 0 0 -20px;
		margin-bottom: 40px;

		> * {
			flex: 1;
			margin: 20px 0 0 20px;
		}

		> span:first-child {
			min-width: 500px;

			flex: 1;

			@media screen and (max-width: 650px) {
				min-width: 200px;
			}
		}

		> span:nth-child(2) {
			min-width: 250px;
			max-width: 400px;

			flex: 1;

			@media screen and (max-width: 1387px) {
				max-width: unset;
			}
		}

		> div:nth-child(3) {
			min-width: 280px;
			max-width: 400px;

			flex: 1;

			@media screen and (max-width: 1387px) {
				max-width: unset;
			}
		}

		> button {
			min-width: 160px;
			max-width: 160px;

			flex: 1;

			@media screen and (max-width: 1387px) {
				max-width: unset;
			}
		}

		> div:nth-child(5) {
			min-width: 280px;
			max-width: 400px;

			flex: 1;

			@media screen and (max-width: 1387px) {
				max-width: unset;
			}
		}
	}
}
