@import '../../variables.scss';

.new-container {
	height: 100%;
	.page-section {
		max-width: 1000px;

		div {
			width: 100%;
		}

		> div:last-child {
			padding-right: 50px;
		}

		> .flex {
			margin-bottom: 42px;
			align-items: flex-end;

			button {
				margin-left: 13px;

				span {
					height: 18px;
				}
			}
		}

		.ant-space {
			label {
				display: block;
				color: $gray-9;
				font-weight: 400;
				height: 25px;
			}
		}
	}
}
