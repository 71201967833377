@import '../../../../../styles/variables.scss';

.new-delivery {
	.ant-space {
		display: flex;
		margin-bottom: 30px;

		> div {
			flex: 1;

			> div {
				> label {
					margin-bottom: 3px;
				}

				> .ant-picker {
					width: 100%;
				}

				.pln {
					margin-left: 3px;
				}
			}
		}
	}

	> .client-info {
		margin-bottom: 35px;
	}

	> .page-section {
		display: flex;
		flex-direction: column;

		> textarea {
			height: 90px;
			margin-bottom: 35px;
		}
	}
}
