@import '../../variables.scss';

.order-details {
	flex: 1;
	height: 1px;

	.priceType {
		color: $blue-9;
		font-weight: 500;
	}

	.pln {
		margin-left: 10px;
		margin-right: 25px;
	}

	div {
		width: 100%;
	}

	.ant-table-wrapper {
		height: unset;
		margin-bottom: 40px;
	}

	.ant-modal-body {
		flex: 1;
	}

	// Left section
	> div:first-child {
		margin-right: 50px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;

		> .page-section {
			> table {
				width: 100%;
				margin-bottom: 35px;

				tr {
					width: 100%;
				}

				td {
					width: 50%;
					padding-bottom: 30px;

					> p {
						padding-bottom: 5px;

						.user-data {
							font-size: 24px;
							font-weight: 400;
							color: $blue-7;
						}
					}

					&:last-child {
						padding-left: 12.5px;
					}
				}
			}

			// Advances
			&:nth-child(2) > table {
				margin-bottom: unset;
			}

			// Payments
			&:last-child {
				& > div.price {
					margin-bottom: 35px;
				}

				& > table td {
					padding: unset;
					padding-bottom: 15px;

					> .save-invoice {
						display: flex;
						align-items: end;
						justify-content: center;
					}
				}
			}
		}

		.table {
			table {
				td:last-child {
					text-align: end;

					a,
					span {
						font-size: 18px;
						font-weight: 500;
					}

					.creation-data {
						font-weight: 500;
						color: $blue-9;
						font-size: 18px;
						padding-left: 10px;
					}
				}
			}

			margin-bottom: 75px;
		}

		.buttons {
			display: flex;
			flex-wrap: wrap;

			margin: -20px 0 65px -20px;

			> * {
				margin: 20px 0 0 20px;

				flex: 1;
				min-width: min-content;
			}
		}
	}

	@media (max-width: 1400px) {
		// Left section
		> div:first-child {
			margin-right: unset;
		}

		// Right section
		> div:last-child {
			display: none;
		}
	}
}
