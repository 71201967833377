@import '../variables.scss';

.ant-modal-content {
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 50px 90px;

	.ant-modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0;

		.input {
			width: 100%;
			margin-bottom: 60px;
			div {
				width: 100%;
			}

			> div > span {
				margin-left: 10px;
			}
		}
		h3 {
			margin-bottom: 45px;
		}

		> span {
			margin-bottom: 65px;
		}
		> span > svg {
			font-size: 100px;
		}

		> h1 {
			font-size: 36px;
			font-weight: 500;
			color: $gray-9;
			margin-bottom: 45px;
			text-align: center;
		}

		> h3 {
			font-size: 24px;
			font-weight: 400;
			color: $gray-8;
			text-align: center;
		}
	}

	.ant-modal-footer {
		border: none;
		display: flex;
		justify-content: center;

		button:last-child {
			margin-left: 25px;
		}

		.blue {
			background-color: $blue-6;
		}

		.red {
			background-color: $red-6;
		}
	}
}
