@import '../variables.scss';

.iconCircle {
	width: 150px;
	height: 150px;
	border-radius: 100%;
	background-color: $white;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
	position: relative;

	&:hover {
		cursor: pointer;
	}
	span {
		color: $blue-9;
	}
	svg {
		font-size: 80px;
	}

	& + p {
		text-align: center;
		margin: 0;
		margin-top: 10px;
		font-size: 20px;
		font-weight: 500;
		color: $gray-8;

		&:hover {
			cursor: pointer;
		}
	}
}
