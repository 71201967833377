@import '../variables.scss';

.login-wrapper {
	position: relative;
	z-index: 1;
	> svg {
		position: absolute;
		top: -30%;
		left: -40%;
	}
	.version {
		font-size: 18px;
		font-weight: 400;
		color: $gray-7;
		margin-top: 25px;
	}
	.login {
		width: 400px;
		height: 500px;
		position: relative;
		background-color: $white;
		padding: 80px 30px 92px 30px;
		border-radius: 5px;
		box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
			0px 3px 6px -4px rgba(0, 0, 0, 0.12);

		> h1 {
			font-size: 32px;
			font-weight: 600;
			color: $gray-8;
			text-align: center;
			margin-bottom: 0;
		}

		> h3 {
			font-size: 18px;
			font-weight: 400;
			color: $gray-7;
			margin-bottom: 19px;
		}

		.h5 {
			height: 21px;
			margin-bottom: 33px;

			h5 {
				font-size: 14px;
				font-weight: 500;
				color: $red-6;
			}
		}

		> p {
			margin-bottom: 33px;
			height: 22px;
		}

		> div {
			width: 100%;
			margin-bottom: 65px;
			display: flex;

			[data-icon='eye']:hover {
				fill: $blue-6;
				cursor: pointer;
			}
		}
	}
}
