//Variables

$backgroundGray: #f0f2f5;
$white: #ffffff;

$gray-6: #bfbfbf;
$gray-7: #8c8c8c;
$gray-8: #595959;
$gray-9: #262626;

$blue-6: #1890ff;
$blue-7: #096dd9;
$blue-9: #003a8c;

$red-6: #f5222d;

$green-6: #52c41a;
