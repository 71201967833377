@import '../../variables.scss';

.drivers {
	width: 100%;
	overflow: hidden;

	.inputs {
		flex-wrap: wrap;
		margin: -20px 0 0 -20px;
		margin-bottom: 40px;

		> * {
			flex: 1;
			margin: 20px 0 0 20px;
		}

		> span:first-child {
			min-width: 500px;

			flex: 1;

			@media screen and (max-width: 650px) {
				min-width: 200px;
			}
		}

		> div:nth-child(2) {
			min-width: 250px;
			max-width: 400px;

			flex: 1;

			@media screen and (max-width: 1095px) {
				max-width: unset;
			}
		}

		> button {
			min-width: 160px;
			max-width: 160px;

			flex: 1;

			@media screen and (max-width: 1095px) {
				max-width: unset;
			}
		}
	}
}
