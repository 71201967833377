@import '../../../../../styles/variables.scss';

.delivery-details {
	.page-section {
		display: flex;
		flex-direction: column;
		margin-bottom: 30px;

		> .ant-space > div {
			flex: 1;
			margin-bottom: 25px;

			> div {
				> label {
					margin-bottom: 3px;
				}

				> span {
					font-size: 24px;
					font-weight: 400;
					color: $blue-7;
				}

				.price-type {
					color: $blue-7;
				}

				.date {
					align-items: center;

					> :last-child {
						margin-left: 5px;
					}
				}

				.payment-type {
					.ant-select {
						flex: 1;
						margin-right: 5px;
					}
				}

				.waste-type {
					> .ant-select {
						flex: 1;
					}

					> button {
						margin-left: 5px;
					}
				}

				.address {
					> button {
						margin-left: 5px;
					}
				}

				.price-edit {
					> .ant-select {
						margin-right: 10px;
					}
				}
			}

			.pln {
				margin-left: 3px;
			}
		}
	}
}
