.settings {
	overflow-y: auto;

	.ant-space {
		align-items: flex-end;
	}

	.page-section:first-child {
		div {
			width: 100%;
		}
		.ant-space {
			align-items: flex-end;
		}

		.page-section {
			height: unset;
			margin-top: 75px;
			.ant-space {
				margin-bottom: 48px;
			}
		}
	}

	.page-section:last-child {
		.ant-table-wrapper {
			height: unset;
			margin-bottom: 45px;
		}
	}
}
