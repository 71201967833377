@import '../../variables.scss';

.task-closing {
	height: 1px;
	flex: 1;

	.page-section {
		height: unset;

		.ant-table-wrapper {
			height: 300px;
		}

		.ant-space {
			width: 100%;
			align-items: flex-end;
			margin-bottom: 45px;

			> div {
				flex: 1;

				> div {
					flex: 1;
				}

				> span {
					margin-bottom: 5px;
				}

				.PLN {
					margin-left: 5px;
				}
			}
		}

		table.finances {
			tbody {
				tr {
					display: flex;
					width: 100%;

					td {
						padding-bottom: 35px;
						flex: 1;

						.price-type {
							color: $blue-7;
						}

						p {
							.user-data {
								font-size: 24px;
								font-weight: 400;
								color: $blue-7;
							}
						}
					}
				}
			}
		}
	}

	.wz {
		margin-bottom: 25px;
	}

	.ant-checkbox-wrapper {
		span {
			width: 25px;
			height: 25px;
		}

		.ant-checkbox-inner::after {
			width: 8px;
			height: 16px;
		}
	}
}
