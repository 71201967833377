@import '../variables.scss';

.wrapper {
	width: 100%;
	height: 100%;
	padding: 20px;

	.page {
		width: 100%;
		height: 100%;
		background-color: $white;
		box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
			0px 3px 6px -4px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		position: relative;
		overflow: hidden;

		.header {
			height: 75px;
			padding: 0px 20px;
			box-shadow: 0px 1px 8px -1px rgba(0, 0, 0, 0.25);
			justify-content: flex-start;

			// > div {
			// 	flex: 1;
			// 	height: 100%;
			// }

			.back,
			.home {
				width: 40px;
				height: 40px;
				border-radius: 100%;
				border: 1px solid #d9d9d9;
				margin-left: 15px;
				margin-right: 25px;

				&:hover {
					cursor: pointer;
				}
			}
			> h1 {
				margin: 0;
			}

			.home {
				position: absolute;
				right: 15px;
			}
		}

		.content {
			flex: 1;
			padding: 25px;
			position: relative;
			overflow: hidden;

			> svg {
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 0;
			}

			.footer {
				padding-top: 25px;
				display: flex;
				flex-wrap: wrap;
				border-top: 1px solid $gray-6;
				column-gap: 20px;
				row-gap: 20px;

				> div {
					display: flex;
					flex-wrap: wrap;
					column-gap: 20px;
					row-gap: 20px;

					&:first-child {
						> button {
							flex: 1;
							min-width: min-content;
						}
					}

					&:last-child {
						align-items: center;
						justify-content: center;
						column-gap: 10px;

						> span {
							white-space: nowrap;
							font-size: 24px;

							&:first-child {
								font-weight: 400;
								color: $gray-8;
							}

							&:last-child {
								font-weight: 600;
								color: $blue-9;
							}
						}
					}
				}
			}
		}
	}
}
