.orders {
	height: 1px;
	flex: 1;

	.inputs {
		width: 100%;
		flex-wrap: wrap;
		margin: -20px 0 40px -20px;

		.ant-space {
			div {
				display: flex;
				flex: 1;
			}
		}
		> * {
			flex: 1;
			margin: 20px 0 0 20px;
		}

		> span:first-child {
			min-width: 500px;
			flex: 1;

			@media screen and (max-width: 650px) {
				min-width: 200px;
			}
		}

		> button {
			min-width: 160px;
			max-width: 160px;

			flex: 1;

			@media screen and (max-width: 1100px) {
				max-width: unset;
			}
		}

		> div:nth-child(3) {
			min-width: 280px;
			max-width: 400px;

			flex: 1;

			@media screen and (max-width: 1387px) {
				max-width: unset;
			}
		}
	}
}
