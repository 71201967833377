@import '../../../../../styles/variables.scss';

.new-own {
	> .ant-space {
		flex: 1;
		margin-bottom: 25px;

		.ant-space-item {
			flex: 1;
		}

		.pln {
			margin-left: 3px;
		}
	}

	.user-data {
		font-size: 24px;
		font-weight: 400;
		color: $blue-6;
	}
}
