.new-client {
	height: 100%;

	.page-section {
		max-width: 1000px;

		div {
			width: 100%;
		}

		.inputs {
			margin-bottom: 40px;
		}
	}
}
