.stats {
	display: flex;
	flex: 1;
	align-items: stretch;
	overflow: hidden;

	.ant-tabs {
		width: 100%;

		.ant-tabs-content-holder {
			.ant-tabs-content {
				height: 100%;

				> .ant-tabs-tabpane {
					display: flex;
					flex-direction: column;

					.inputs {
						margin: -20px 0 35px -20px;
						display: flex;
						flex-wrap: wrap;

						> * {
							margin: 20px 0 0 20px;
							flex: 1;
						}
					}

					.chart {
						margin-top: 25px;
						height: 400px;
					}
				}
			}
		}
	}
}
