.sign-wrapper {
	width: 100%;
	height: 100%;
	box-shadow: 0px 0px 48px rgba(0, 0, 0, 0.15);
	display: flex;

	.sigCanvas {
		width: 100%;
		height: 100%;
	}
}
