@import './variables.scss';
@import './table.scss';

*,
html,
h3,
p {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
}

.flex {
	display: flex;

	&.flex-dir {
		flex-direction: column;
	}

	&.flex-jc {
		justify-content: center;
	}

	&.flex-jsb {
		justify-content: space-between;
	}

	&.flex-ac {
		justify-content: center;
		align-items: center;
	}

	&.flex-aend {
		align-items: flex-end;
	}
}

.flex-1 {
	flex: 1;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
	position: fixed;
}

#app {
	height: -webkit-fill-available;
	overflow-y: auto;
	background-color: $backgroundGray;
}

.container {
	min-height: 900px;
	width: 100vw;
	height: 100%;
	position: relative;

	.starblob {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 0;
		// display: none;
	}

	.blob2 {
		position: absolute;
		right: 0;
		bottom: 0;
		// display: none;
	}

	> h3 {
		width: 100%;
		text-align: center;
		position: absolute;
		font-size: 18px;
		font-weight: 300;
		color: $gray-6;
		bottom: 10px;
	}

	.panel {
		width: 100vw;
		height: 100%;
		padding: 30px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 1fr 1fr 3fr 2fr;
		grid-row-gap: 50px;

		.claim {
			grid-column: 1/4;

			&:nth-of-type(2) {
				margin-top: -40px;
			}

			.alert {
				grid-column: 1/4;
				height: 100px;

				// .ant-alert {
				> span {
					font-size: 30px;
				}
				.ant-alert-message {
					font-size: 22px;
					font-weight: 400;
					margin-left: 15px;
					grid-row-gap: 0;
					> span {
						font-size: 24px;
						font-weight: 600;
						margin-left: 10px;
					}
				}
				// }
			}

			.toClaim {
				grid-column: 1/4;
				background-color: #fffbe6;
				padding: 0 15px;
				table {
					width: 100%;
					th {
						padding-bottom: 15px;
						font-size: 20px;
						color: #595959;
					}

					td {
						font-size: 18px;
						font-weight: 500;
						padding-bottom: 15px;

						svg {
							margin-left: 5px;
							font-size: 35px;
							color: $blue-9;
						}
					}
				}
			}
		}

		.middle-section {
			grid-column: 1/4;
			flex: 1;
			table {
				max-width: 500px;
				td {
					padding: 3px;
					font-weight: 300;
					font-size: 24px;

					&:first-child {
						color: $gray-7;
					}

					&:last-child {
						font-weight: 600;
						text-align: end;
						padding-left: 30px;
						color: $blue-9;
					}
				}
			}

			.task {
				width: 686px;
				height: 160px;
				padding: 40px 28px 40px 28px;
				margin-bottom: 30px;
				justify-content: flex-start;
				background-color: $white;
				border-radius: 12px;
				box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08),
					0px 3px 6px -4px rgba(0, 0, 0, 0.12);

				&:hover:not(.no-next) {
					cursor: pointer;
				}

				> span {
					margin-right: 28px;
					svg {
						font-size: 70px;
						color: $blue-9;
					}
				}

				h1,
				h2 {
					color: $gray-8;
					margin-bottom: 0;
				}
				h1 {
					font-size: 32px;
					font-weight: 600;
				}
				h2 {
					font-size: 28px;
					font-weight: 400;
				}
			}
		}
	}

	.ant-timeline-item {
		color: $gray-8;
		font-size: 24px;
		padding: 12px;

		.ant-timeline-item-tail {
			height: 100%;
			left: 12px;
		}

		.ant-timeline-item-head {
			top: 24px;
			left: 13px;
		}

		span {
			color: $gray-9;
		}

		a {
			text-decoration: underline;
		}
	}

	.print:not(.visible) {
		display: none;
	}
}

.ant-select-dropdown {
	.ant-select-item.ant-select-item-option {
		min-height: 52px;
		display: flex;
		align-items: center;

		> div {
			font-size: 18px;
		}
	}
}

.ant-picker-panel {
	.ant-picker-date-panel {
		width: unset;

		.ant-picker-header {
			height: 56px;
			display: flex;
			align-items: center;
			// transform: scale(2);

			> button {
				width: 42px;
				margin-right: 8px;

				> span {
					&::after,
					&::before {
						border-color: #595959;
						border-width: 2.5px 0 0 2.5px;
						width: 16px;
						height: 16px;
					}
				}
			}

			.ant-picker-header-view {
				font-size: 24px;
			}
		}

		.ant-picker-body .ant-picker-content {
			width: unset;

			.ant-picker-cell .ant-picker-cell-inner {
				font-size: 24px;
				padding: 6px;
				height: unset;
				margin: 8px;
			}
		}
	}

	.ant-picker-time-panel .ant-picker-content .ant-picker-time-panel-column {
		width: 86px;

		.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
			font-size: 24px;
			padding: 4px;
			margin: 12px 0;
			height: unset;
			text-align: center;
		}
	}

	.ant-picker-footer {
		height: 64px;

		.ant-picker-ranges {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: 100%;

			.ant-picker-ok > button {
				width: 64px;
				height: 32px;

				> span {
					font-size: 16px;
				}
			}
		}
	}
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		position: initial !important;
		box-sizing: initial !important;
	}

	@page {
		size: A4;
		margin: 16px;
	}
}
