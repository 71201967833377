@import '../../variables.scss';

.container-details {
	flex: 1;
	height: 1px;

	.page-section:first-child {
		margin-right: 50px;
		overflow-y: auto;

		div {
			width: 100%;
		}

		> .flex {
			align-items: flex-end;
			> .ant-space {
				margin-bottom: 40px;
			}

			&.flex:nth-child(3) {
				padding-right: 50px;
			}

			.circle {
				margin-left: 13px;
				margin-bottom: 40px;

				span {
					height: 18px;
				}
			}
			table {
				td:nth-child(2) {
					text-align: end;
				}
				a {
					font-size: 18px;
					font-weight: 500;
				}
				.user-data {
					font-weight: 500;
					color: $blue-9;
					font-size: 18px;
					padding-left: 10px;
				}
			}
		}

		> .page-section {
			height: 1px;
			flex: 1;
			margin-top: 35px;

			.flex {
				align-items: flex-end;
				button {
					margin-left: 25px;
				}
				.ant-space {
					margin-top: 40px;
					align-items: flex-end;
				}

				table {
					width: 100%;
					tr:first-child {
						td {
							padding-bottom: 40px;
						}
					}
					.lend {
						font-size: 24px;
						font-weight: 400;
						color: $blue-7;
					}
				}
			}
		}
	}
	@media (max-width: 1400px) {
		.page-section:first-child {
			margin-right: unset;
		}
		> .page-section:last-child {
			display: none;
		}
	}
}
