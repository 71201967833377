@import '../../variables.scss';

#agenda {
	width: 100%;
	height: 100%;

	.page > .content {
		padding: 15px;

		.tasks {
			flex: 1;
			height: 1px;

			.calendar-wrapper {
				> h2 {
					width: 100%;
					align-self: center;
					text-align: center;
				}

				#body {
					width: 100%;

					display: flex;
					flex-direction: column;
					position: relative;
				}
			}
		}
	}
}
