.new-expense {
	flex: 1;
	height: 1px;

	.page-section {
		max-width: 1000px;

		> .ant-space {
			margin-bottom: 40px;
		}

		.pln span {
			padding-left: 10px;
		}

		div {
			width: 100%;
		}
	}
}
