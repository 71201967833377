@import '../../variables.scss';

.new-driver {
	height: 100%;
	.page-section {
		max-width: 1000px;

		.ant-space {
			width: 100%;
			display: flex;
			margin-bottom: 36px;

			> div {
				flex: 1;

				label {
					color: $gray-9;
					font-weight: 400;
					height: 25px;
				}
				.info {
					border: none;
					height: 100%;
				}
			}
		}
		.password {
			font-size: 22px;
			font-weight: 400;
			color: $gray-8;

			> span {
				font-size: 22px;
				font-weight: 700;
				color: $gray-9;
				padding-left: 17px;
			}
		}

		.info {
			font-size: 14px;
			font-weight: 300;
			color: $gray-7;
			border-left: 2px solid $blue-6;
			padding-left: 4px;
		}
	}
}
