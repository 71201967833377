@import '../../variables.scss';

.container-selection {
	height: 1px;
	flex: 1;

	.page-section {
		table {
			margin-top: 33px;
		}
		table,
		tbody {
			width: 100%;
		}

		tbody {
			tr {
				display: flex;
				width: 100%;

				td {
					padding-bottom: 35px;
					flex: 1;
					> p {
						.user-data {
							font-size: 24px;
							font-weight: 400;
							color: $blue-7;
						}
					}

					p:first-child {
						padding-bottom: 20px;
					}

					button {
						align-self: start;
					}
				}
			}
		}
	}
	.page-section:last-child {
		visibility: hidden;
	}
}
