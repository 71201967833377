@import '../../variables.scss';

.expenses__get-money {
	height: 1px;
	flex: 1;

	.page-section {
		max-width: 1000px;

		> div {
			width: 100%;
			margin-bottom: 30px;

			> div {
				width: 100%;

				> div {
					width: 100%;

					> * {
						width: 100%;
					}

					> div > span {
						width: unset;
						margin-left: 5px;
					}

					.data {
						color: $blue-7;
						font-weight: 600;
						font-size: 22px;
					}
				}
			}
		}
	}
}
